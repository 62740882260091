import React from "react";
import {Props as ViewProps, View} from "./View";
import {TagName} from "./Enums/TagName";
import Markdown from 'markdown-to-jsx'

interface Props extends ViewProps {
    t?: string,
    lk?: string,
}

export const Text: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        whiteSpace: "pre-wrap",
        flexShrink: 0,
        maxWidth: "100%",
        ...props.style
    }

    return (
        <View tagName={TagName.span} {...props} style={style}>
            {props.t ? props.t : null}
            {props.lk ? <Markdown>{props.lk}</Markdown> : null}
        </View>
    )
}
