import React from "react";
import {Props as ViewProps} from "../../Components/SwiftUI/View";
import {VStack} from "../../Components/SwiftUI/VStack";
import {DailyDealsCarouselV} from "../Daily Deals/DailyDealsCarouselV";
import {HStack} from "../../Components/SwiftUI/HStack";
import {Text} from "../../Components/SwiftUI/Text";
import {Spacer} from "../../Components/SwiftUI/Spacer";
import {NavigationLink} from "../../Components/SwiftUI/NavigationLink";
import {Color} from "../../Components/SwiftUI/Enums/Color";
import {HorizontalAlignment} from "../../Components/SwiftUI/Enums/HorizontalAlignment";
import {Fonts} from "../../Constants/Fonts";
import {useQuery} from "@apollo/client";
import {GetHomeContentQuery} from "../../Server API/GraphQL/App/Queries";
import {LoaderV} from "../../Components/LoaderV";
import {SeeMoreInAppV} from "../../Components/SeeMoreInAppV";
import {ForEach} from "../../Components/SwiftUI/ForEach";
import {AppThumbnailV} from "../../Components/AppThumbnailV";
import {ScrollView} from "../../Components/SwiftUI/ScrollView";
import {Axis} from "../../Components/SwiftUI/Enums/Axis";
import {Toolbox} from "../../Utilities/Toolbox";
import {IconV} from "../../Components/IconV";
import {AppleImageParams} from "../../Constants/AppleImageParams";

interface Props extends ViewProps {}

export const HomeV: React.FC<Props> = (props) => {

    const { loading, error, data } = useQuery(GetHomeContentQuery, {
        variables: {
            pageSize: 9
        }
    })

    const deals: any[] = data?.dailyDeals.content ?? []
    const popularApps: any[] = data?.popularApps.content ?? []
    const appsOnSale: any[] = data?.appsOnSale.content.map((a: any) => a.app) ?? []
    const newestApps: any[] = data?.newestApps.content.map((a: any) => a.app) ?? []
    const preorders: any[] = data?.preorders.content ?? []
    const updatedApps: any[] = data?.updatedApps.content.map((a: any) => a.app) ?? []

    function appCarouselV(title: string, apps: any[], iconsOnly: boolean = false, destination: string) {
        return (
        <VStack stretch alignment={HorizontalAlignment.leading} padding={{top: 8, bottom: 12}} cornerRadius={14} shadow={{color: Color.lightBorder, radius: 4}}>

            <NavigationLink destination={destination} padding={{horizontal: 16}}>
                <HStack>
                    <Text t={title}
                          font={Fonts.title}
                          foregroundStyle={Color.primary} />
                    <Spacer />
                    <Text t={"See All"} />
                </HStack>
            </NavigationLink>

            {iconsOnly ?
                <ScrollView axes={Axis.horizontal} showsIndicators={false}>
                    <NavigationLink destination={destination}>
                        <HStack padding={{horizontal: 16}} scrollTargetLayout>
                            <ForEach data={apps} id={app => app.id} content={app =>
                                <IconV src={Toolbox.getImageUrl(app.artworkUrl, AppleImageParams.listIcon)} frame={{width: 62}} />
                            } />
                        </HStack>
                    </NavigationLink>
                </ScrollView>
            :
                <ScrollView axes={Axis.horizontal} showsIndicators={false}>
                    <HStack spacing={32} padding={{horizontal: 16}} scrollTargetLayout>
                        <ForEach data={Toolbox.chunkArray(apps, 3)} id={chunk => (chunk as any[])[0].id} content={chunk =>
                            <VStack stretch spacing={8} frame={{width: Math.min(window.innerWidth - 48, 400)}}>
                                <ForEach data={chunk} id={app => app.id} content={app =>
                                    <NavigationLink destination={`/app/${app.id}`}>
                                        <AppThumbnailV app={app} frame={{height: 56}}/>
                                    </NavigationLink>
                                } />
                            </VStack>
                        } />
                    </HStack>
                </ScrollView>
            }
        </VStack>
        )
    }

    return (
        <VStack spacing={10} padding={{horizontal: 8}} style={{overflow: "visible"}}>

            <LoaderV loading={loading} error={error} />

            <DailyDealsCarouselV deals={deals} scrollClipDisabled={8} />

            <VStack stretch spacing={10} padding={{bottom: 8}}>

                {appCarouselV("Popular", popularApps, true, "apps/popular")}
                {appCarouselV("On Sale!", appsOnSale, false, "activity/pricedrops")}
                {appCarouselV("New Releases", newestApps, false, "activity/new")}
                {appCarouselV("Pre-Orders", preorders, false, "apps/preorders")}
                {appCarouselV("Recently Updated", updatedApps, true, "activity/updates")}

                <SeeMoreInAppV padding={{horizontal: 8}} />

            </VStack>
        </VStack>
    )
}
