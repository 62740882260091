import React, {ReactElement} from "react";
import {Props as ViewProps, View} from "./View";
import {Spacer} from "./Spacer";
import {HorizontalAlignment} from "./Enums/HorizontalAlignment";

interface Props extends ViewProps {
    alignment?: HorizontalAlignment
    spacing?: number
    stretch?: boolean
    fit?: boolean
}

export const VStack: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        width: props.stretch ? "100%" : undefined,
        height: props.fit ? undefined : "100%",
        minHeight: "fit-content",                        // TODO: Only in ScrollView?
        maxWidth: "100%",                                // Fixes multiline text overflow
        display: "flex",
        flexDirection: "column",
        alignItems: props.alignment ?? HorizontalAlignment.center,
        gap: props.spacing ?? 8,
        ...props.style
    }

    return (
        <View {...props} style={style}>
            {React.Children.map(props.children, child => {
                if (!React.isValidElement(child) || child.type !== Spacer) { return child }
                return React.cloneElement(child as ReactElement, {
                    style: {minHeight: child.props.minLength}
                })
            })}
        </View>
    );
};
