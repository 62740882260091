import React from "react";
import {Props as ViewProps, View} from "./View";
import {Color} from "./Enums/Color";

interface Props extends ViewProps {
    colors: Color[],
    startPoint?: number,
    endPoint?: number
}

export const LinearGradient: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        background: `linear-gradient(${props.colors[0]} ${props.startPoint ?? 0}%, ${props.colors[1]} ${props.endPoint ?? 100}%)`,
        width: "100%",
        height: "100%",
        ...props.style
    }

    return (
        <View {...props} style={style} />
    )
}
