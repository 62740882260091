import React, {ReactNode, SetStateAction, useEffect, useRef} from "react";
import {Props as ViewProps, View} from "./View";
import {ForEach} from "./ForEach";
import {Text} from "./Text";
import {Color} from "./Enums/Color";
import {Section} from "./Section";

interface Props<T extends string | number | null> extends ViewProps {
    selection: [T, React.Dispatch<SetStateAction<T>>]
}

export const Picker = <T extends string | number | null,>(props: Props<T>): React.ReactElement => {

    const [selection, setSelection] = props.selection
    const selectRef = useRef<HTMLSelectElement>(null)

    function mapChildren(children: ReactNode): ReactNode {
        return React.Children.map(children, child => {
            if (!React.isValidElement(child)) { return child }
            if (child.type === Text) {
                return <option value={child.props.tag ?? "null"}>{child.props.t}</option>
            } else if (child.type === ForEach) {
                const children = (child.props.data as any[]).map(item => child.props.content(item))
                return mapChildren(children)
            } else if (child.type === Section) {
                return <optgroup label={child.props.title}>
                           {mapChildren(child.props.children)}
                       </optgroup>
            } else {
                return mapChildren(child.props.children)
            }
        })
    }

    const style: React.CSSProperties = {
        color: Color.tint,
        transition: "opacity 0.4s ease-in-out",
        ...props.style
    }

    const selectStyle: React.CSSProperties = {
        background: "rgb(255, 255, 255, 0.002)",
        color: "unset",
        font: "unset",
        border: "unset",
        outline: "unset",
        textAlignLast: "center",
        WebkitTapHighlightColor: "transparent"
    }

    function adjustWidth() {
        if (selectRef.current) {
            const selectedOption = selectRef.current.options[selectRef.current.selectedIndex]
            const tempSpan = document.createElement('span')
            tempSpan.style.visibility = 'hidden'
            tempSpan.style.position = 'absolute'
            tempSpan.style.whiteSpace = 'nowrap'
            tempSpan.innerText = selectedOption.text
            document.body.appendChild(tempSpan)
            selectRef.current.style.width = `${tempSpan.offsetWidth + 40}px`
            document.body.removeChild(tempSpan)
        }
    }

    useEffect(() => {
        adjustWidth()
    }, [selection])

    return (
        <View className={"Picker"} {...props} style={style}>
            <select ref={selectRef} style={selectStyle} value={selection ?? "null"} onChange={e =>
                setSelection((e.target.value === "null" ? null : e.target.value) as T)}
            >
                {mapChildren(props.children)}
            </select>
        </View>
    )
}
