import React from "react"
import {Props as ViewProps, View} from "../../../Components/SwiftUI/View"
import {VStack} from "../../../Components/SwiftUI/VStack"
import {ForEach} from "../../../Components/SwiftUI/ForEach"
import {AppThumbnailV} from "../../../Components/AppThumbnailV"
import {useQuery} from "@apollo/client"
import {Color} from "../../../Components/SwiftUI/Enums/Color"
import {LoaderV} from "../../../Components/LoaderV";
import {NavigationLink} from "../../../Components/SwiftUI/NavigationLink";
import {useLocation, useParams} from "react-router-dom";
import {FilterAppsQuery} from "../../../Server API/GraphQL/App/Queries";
import {Spacer} from "../../../Components/SwiftUI/Spacer";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {Text} from "../../../Components/SwiftUI/Text";
import {Rectangle} from "../../../Components/SwiftUI/Rectangle";
import {HorizontalAlignment} from "../../../Components/SwiftUI/Enums/HorizontalAlignment";
import {Fonts} from "../../../Constants/Fonts";
import {AppSortBy} from "../../../Model/AppSortBy";

interface Props extends ViewProps {}

export const AppsV: React.FC<Props> = (props) => {

    const { type } = useParams<{ type: string }>()
    let { state } = useLocation();

    let title = state?.title ?? "Apps"
    let appFilter = state?.appFilter ?? {}
    let appSort = state?.appSort ?? {}

    switch (type) {
        case "popular":
            title = "Popular"
            appSort = {by: AppSortBy.popularityDay}
            break
        case "preorders":
            title = "Pre-Orders"
            appFilter = {preorder: true}
            appSort = {by: AppSortBy.addedDate}
            break
    }

    const { loading, error, data, fetchMore } = useQuery(FilterAppsQuery, {
        variables: {
            appFilter: appFilter,
            appSort: appSort,
            page: 0
        }
    })

    function loadData() {
        fetchMore({ variables: {
            page: data.filterApps.nextPageable.pageNumber
        }})
    }

    const apps: any[] = data?.filterApps.content ?? []

    return (
        <VStack stretch navigationTitle={title} background={Color.groupedBackground}>

            <HStack background={Color.white} cornerRadius={{bottom: 14}} shadow={{radius: 2, y: 4, color: "rgb(0, 0, 0, 0.08)"}}>
                <Rectangle frame={{height: 12}} />
            </HStack>

            <Spacer frame={{height: 8}} />

            <VStack stretch spacing={6} alignment={HorizontalAlignment.leading}>

                <Text t={"Apps"}
                      font={Fonts.title}
                      foregroundStyle={Color.gray}
                      padding={{leading: 16}} />

                <VStack stretch spacing={0} background={Color.white} cornerRadius={14}>
                    <ForEach data={apps} id={app => app.id} content={app =>
                        <NavigationLink destination={`/app/${app.id}`} padding={{horizontal: 12, vertical: 6}}>
                            <AppThumbnailV app={app} frame={{height: 62}} />
                        </NavigationLink>
                    } />
                </VStack>

            </VStack>

            <LoaderV loading={loading} error={error} />
            {data?.filterApps.hasNext ? <View onAppear={loadData} /> : null}
        </VStack>
    )
}