import React from 'react';
import {Props as ViewProps, View} from "./View";
import {Text} from "./Text";
import {Color} from "./Enums/Color";

interface Props extends ViewProps {
    t: string
    action: () => void
    disabled?: boolean
}

export const Button: React.FC<Props> = (props) => {

    const buttonStyle: React.CSSProperties = {
        WebkitAppearance: "none",
        WebkitTapHighlightColor: "transparent",
        background: "none",
        border: "none",
        padding: 0,
        transition: "opacity 0.1s ease-out",
        display: "flex"                          // Fixes bug with vertical padding
    }

    const labelStyle: React.CSSProperties = {
        color: Color.tint,
        ...props.style
    }

    return (
        <View>
            <button style={buttonStyle}
                onClick={props.disabled ? undefined : props.action}
                disabled={props.disabled}
            >
                <Text {...props} style={labelStyle}/>
            </button>
        </View>
    );
};
