import {AppleImageParams} from "../Constants/AppleImageParams";
import {ConfigConstants} from "../Constants/ConfigConstants";
import {Color} from "../Components/SwiftUI/Enums/Color";
import {LocaleStorage} from "./LocaleStorage";
import {TimeInterval} from "../Constants/TimeConstants";

export enum MediaType {
    userIcon = "/user/icons/",
    userBanner = "/user/banners/",
    userLink = "/user/links/",
}

export class Toolbox {

    static chunkArray<T>(array: T[], chunkSize: number): T[][] {
        const chunks: T[][] = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }

        return chunks;
    }

    static formatDeviceSupport(devices: [string]): string {

        let appleTv = false, appleWatch = false, iPad = false, iPhone = false, mac = false, vision = false;

        for (const device of devices) {
            switch (device) {
                case "APPLE_TV":
                    appleTv = true;
                    break;
                case "APPLE_WATCH":
                    appleWatch = true;
                    break;
                case "IPAD":
                    iPad = true;
                    break;
                case "IPHONE":
                    iPhone = true;
                    break;
                case "MAC":
                    mac = true;
                    break;
                case "APPLE_VISION":
                    vision = true;
                    break;
            }
        }

        if ((iPhone && iPad) || (iPhone && mac)) {
            return "Universal";
        } else if (iPhone) {
            return "iPhone";
        } else if (iPad) {
            return "iPad";
        } else if (mac) {
            return "Mac";
        } else if (appleWatch) {
            return "Watch";
        } else if (appleTv) {
            return "Apple TV";
        } else if (vision) {
            return "Vision";
        } else {
            return "Unknown";
        }
    }

    static getImageUrl(rawUrl: string, params: AppleImageParams): string {
        return rawUrl.replace("{w}x{h}{c}.{f}", params)
    }

    static formatGenres(genres: [any], isGame: boolean): string {
        let formatted: string | null = null;

        if (isGame) {
            const subgenres = genres
                .filter(genre => genre.parentITunesId === ConfigConstants.gameGenreId)
                .map(genre => genre.title)
                .join(", ");
            formatted = subgenres.length > 0 ? subgenres : null;
        }

        if (!isGame || formatted === null) {
            formatted = genres.length > 0 ? genres[0].title : null;
        }

        return formatted ?? "";
    }

    static removeZeros(string: string): string {
        if (!string.includes('.')) {
            return string;
        }
        let finalString = string;
        while (finalString.endsWith('0')) {
            finalString = finalString.slice(0, -1);
        }
        if (finalString.endsWith('.')) {
            finalString = finalString.slice(0, -1);
        }
        return finalString;
    }

    static formatNumber(number: number, zero: boolean = false): string {
        switch (true) {
            case number === 0:
                return zero ? "0" : "-";
            case number < 1_000:
                return Toolbox.removeZeros(number.toString());
            case number < 100_000:
                return Toolbox.removeZeros((number / 1_000).toFixed(1)) + "k";
            case number < 1_000_000:
                return Toolbox.removeZeros((number / 1_000).toFixed(0)) + "k";
            case number < 100_000_000:
                return Toolbox.removeZeros((number / 1_000_000).toFixed(1)) + "m";
            case number < 1_000_000_000:
                return Toolbox.removeZeros((number / 1_000_000).toFixed(0)) + "m";
            case number < Number.POSITIVE_INFINITY:
                return Toolbox.removeZeros((number / 1_000_000_000).toFixed(2)) + "b";
            default:
                return "∞";
        }
    }

    static priceChangeTypeColor(type: string): Color {
        switch (type) {
            case "DROP": return Color.priceChangeDrop
            case "RISE": return Color.priceChangeRise
            default: return Color.gray
        }
    }

    static updateSizeColor(size: string): Color {
        switch (size) {
            case "HUGE": return Color.updateHuge
            case "LARGE": return Color.updateLarge
            case "REGULAR": return Color.updateRegular
            case "SMALL": return Color.updateSmall
            default: return Color.updateTiny
        }
    }

    static availabilityChangeTypeColor(type: string): Color {
        switch (type) {
            case "PREORDER": return Color.availabilityPreorder
            case "PREORDER_RELEASE": return Color.availabilityPreorderRelease
            case "RELEASE": return Color.availabilityRelease
            case "REMOVAL": return Color.availabilityRemoval
            case "RERELEASE": return Color.availabilityRerelease
            default: return Color.gray
        }
    }

    static formattedDateDeltaFromNow(isoDateString: string): string {
        const date = new Date(isoDateString);
        const now = new Date();
        const delta = (now.getTime() - date.getTime()) / 1000;  // Difference in seconds

        if (Math.abs(delta) < TimeInterval.minute) {
            return "Now";
        } else if (Math.abs(delta) < TimeInterval.hour) {
            return `${Math.floor(delta / TimeInterval.minute)}m`;
        } else if (Math.abs(delta) < TimeInterval.day) {
            return `${Math.floor(delta / TimeInterval.hour)}h`;
        } else if (Math.abs(delta) < TimeInterval.year) {
            return `${Math.floor(delta / TimeInterval.day)}d`;
        } else {
            return `${Math.floor(delta / TimeInterval.year)}y`;
        }
    }

    static activityInfo(activity: any): [Color, string] {
        let activityColor: Color
        let activityLabel: string

        if (activity.__typename === "AppActivityPriceChange") {
            activityLabel = `~~${LocaleStorage.shared.formatPrice(activity.priceTierFrom)}~~`
            activityColor = Toolbox.priceChangeTypeColor(activity.priceChangeType)
        } else if (activity.__typename === "AppActivityUpdate") {
            activityLabel = Toolbox.formattedDateDeltaFromNow(activity.timestamp)
            activityColor = Toolbox.updateSizeColor(activity.updateSize)
        } else if (activity.__typename === "AppActivityAvailability") {
            activityLabel = Toolbox.formattedDateDeltaFromNow(activity.timestamp)
            activityColor = Toolbox.availabilityChangeTypeColor(activity.availabilityChangeType)
        } else {
            activityLabel = ""
            activityColor = Color.gray
        }
        return [activityColor, activityLabel]
    }

    static appPriceLabel(app: any): string {
        return app.arcade ? "Arcade" : LocaleStorage.shared.formatPrice(app.priceTier)
    }

    static formatDate(date: Date, time: boolean): string {
        const now = new Date();
        const delta = (now.getTime() - date.getTime()) / 1000;  // Difference in seconds

        // Handle "now", "minute", "hour", and "day" cases
        if (Math.abs(delta) < TimeInterval.day) {
            if (time) {
                if (Math.abs(delta) < TimeInterval.minute) {
                    return "now";
                } else if (Math.abs(delta) < TimeInterval.hour) {
                    return `${Math.floor(Math.abs(delta) / TimeInterval.minute)}m`;
                } else {
                    return `${Math.floor(Math.abs(delta) / TimeInterval.hour)}h`;
                }
            } else {
                return "today";
            }
        }

        // Handle "yesterday" case
        if (Math.abs(delta) < 2 * TimeInterval.day) {
            return "yesterday";
        }

        // Handle other cases
        const dateYear = date.getFullYear();
        const nowYear = now.getFullYear();
        const template = (dateYear === nowYear && delta < 0) || Math.abs(delta) < 3 * TimeInterval.month
            ? 'd MMM'
            : 'd MMM yyyy';

        return Toolbox.formatDateTemplate(date, template);
    }

    static formatDateTemplate(date: Date, template: string): string {
        const options: Intl.DateTimeFormatOptions = {}
        switch (template) {
            case "d":
                options.day = "numeric"
                options.month = undefined
                break
            case "MMM":
                options.day = undefined
                options.month = "short"
                break
            case "d MMM":
                options.day = "numeric"
                options.month = "short"
                break;
            case "d MMM yyyy":
                options.day = "numeric"
                options.month = "short"
                options.year = "numeric"
                break;
        }

        return new Intl.DateTimeFormat(navigator.language, options).format(date);
    }

    static dateFromComponents(components: { year: number, month: number, day: number }): Date {
        return new Date(components.year, components.month - 1, components.day);
    }

    static copy<T>(object: T): T {
        return JSON.parse(JSON.stringify(object));
    }

    static showAppStore(iTunesId: string) {
        window.location.href = `https://apps.apple.com/app/id${iTunesId}?at=${ConfigConstants.affiliateToken}`
    }

    static formatRatingCount(ratingCount: number): string {
        switch (ratingCount) {
            case 0: return "No Rating"
            case 1: return "1 Rating"
            default: return `${this.formatNumber(ratingCount)} Ratings`
        }
    }

    static mediaById(id: string | null, mediaType: MediaType): string {
        return ConfigConstants.imagesUrl + mediaType + (id ?? "default") + ".jpg"
    }
}
