import React, {useEffect, useState} from "react"
import {Props as ViewProps} from "../../../Components/SwiftUI/View"
import {useQuery} from "@apollo/client";
import {GetAppDetailQuery} from "../../../Server API/GraphQL/App/Queries";
import {useParams} from "react-router-dom";
import {VStack} from "../../../Components/SwiftUI/VStack";
import {LoaderV} from "../../../Components/LoaderV";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {IconV} from "../../../Components/IconV";
import {Toolbox} from "../../../Utilities/Toolbox";
import {AppleImageParams} from "../../../Constants/AppleImageParams";
import {Image} from "../../../Components/SwiftUI/Image";
import {Text} from "../../../Components/SwiftUI/Text";
import {FontWeight} from "../../../Components/SwiftUI/Enums/FontWeight";
import {HorizontalAlignment} from "../../../Components/SwiftUI/Enums/HorizontalAlignment";
import {TextAlignment} from "../../../Components/SwiftUI/Enums/TextAlignment";
import {VerticalAlignment} from "../../../Components/SwiftUI/Enums/VerticalAlignment";
import {Spacer} from "../../../Components/SwiftUI/Spacer";
import {Fonts} from "../../../Constants/Fonts";
import {RatingStarsV} from "../../../Components/RatingStarsV";
import {Button} from "../../../Components/SwiftUI/Button";
import {Color} from "../../../Components/SwiftUI/Enums/Color";
import {FontDesign} from "../../../Components/SwiftUI/Enums/FontDesign";
import {LocaleStorage} from "../../../Utilities/LocaleStorage";
import {TimeInterval} from "../../../Constants/TimeConstants";
import {AppDetailScreenshotsV} from "./AppDetailScreenshotsV";
import {Device} from "../../../Model/Device";
import {ForEach} from "../../../Components/SwiftUI/ForEach";
import {Group} from "../../../Components/SwiftUI/Group";
import {AppDetailVM} from "./AppDetailVM";
import {LinearGradient} from "../../../Components/SwiftUI/LinearGradient";
import {SeeMoreInAppV} from "../../../Components/SeeMoreInAppV";

const thumbsup = require("../../../Assets/hand.thumbsup.fill@3x.png");
const thumbsdown = require("../../../Assets/hand.thumbsdown.fill@3x.png");
const checkmark = require("../../../Assets/checkmark@3x.png");
const bell = require("../../../Assets/bell.fill@3x.png");
const controller = require("../../../Assets/gamecontroller@3x.png")

interface Props extends ViewProps {}

export const AppDetailV: React.FC<Props> = (props) => {

    const { id } = useParams<{ id: string }>();

    const [description, setDescription] = useState<string | null>(null);
    const model = new AppDetailVM()

    const { loading, error, data } = useQuery(GetAppDetailQuery, {
        variables: { id: id },
    });

    useEffect(() => {
        if (data?.app.ITunesId) {
            model.fetchAdditionalInfo(data?.app.ITunesId).then(setDescription)
        }
    }, [data?.app.ITunesId]);

    if (!data) {
        return <LoaderV loading={loading} error={error} />;
    }

    const app = data.app;

    function getTopButtonText(): string | null {
        const lastActivity = app.lastActivity;

        if (lastActivity.__typename === "AppActivityPriceChange") {
            return `~~${LocaleStorage.shared.formatPrice(lastActivity.priceTierFrom)}~~`;
        } else if (lastActivity.__typename === "AppActivityUpdate") {
            return null;
        } else if (lastActivity.__typename === "AppActivityAvailability") {
            if (lastActivity.availabilityChangeType === 'release' &&
                (new Date().getTime() - new Date(lastActivity.timestamp).getTime()) < 2 * TimeInterval.week)
            {
                return "New!";
            }
        }

        return null;
    }

    function getScreenshots() {
        for (const device of Device.sortedAllCases) {
            const screenshots = (app.assets as any[]).filter(asset => asset.type === "SCREENSHOT" && asset.device === device)
            if (screenshots) { return screenshots.slice(0, 5) }
        }
        return []
    }

    const topButtonText = getTopButtonText()

    function appDetailHeaderV() {
        return (
            <HStack alignment={VerticalAlignment.top} spacing={10} padding={16} foregroundStyle={"rgb(0, 0, 0, 0.7)"} background={<Image src={Toolbox.getImageUrl(app.artworkUrl, AppleImageParams.listIcon)} resizeable={true} blur={30} contrast={0.5} saturation={1.5} brightness={0.5} shadow={{radius: 1, y: 2, color: Color.gray}}/>}>
                <VStack spacing={0}>
                    <IconV src={Toolbox.getImageUrl(app.artworkUrl, AppleImageParams.listIcon)}
                           frame={{width: 100, height: 100}}/>

                    <Spacer frame={{height: 8}} />

                    <HStack fit spacing={4}>
                        <ForEach data={Device.sortedAllCases.filter(device => app.devices.includes(device))} id={device => device} content={device =>
                            <Image src={Device.image(device)} frame={{height: 15}} opacity={0.7} />
                        } />
                    </HStack>

                    {app.controller ?
                        <Group>
                        <Spacer frame={{height: 5}} />
                        <Image src={controller} opacity={0.7} frame={{height: 15}} />
                        </Group>
                    : null}
                </VStack>
                <VStack stretch alignment={HorizontalAlignment.leading} spacing={0}>
                    <Spacer frame={{height: 6}} />

                    <Text t={app.title}
                          font={{size: 20, weight: FontWeight.bold}}
                          multilineTextAlignment={TextAlignment.leading} />

                    {app.subtitle ?
                        <Text t={app.subtitle}
                              font={{weight: FontWeight.medium}}/>
                    : null}

                    <Text t={app.developer.name}
                          lineLimit={1}
                          font={Fonts.body} />

                    {!app.subtitle ?
                        <Text t={Toolbox.formatGenres(app.genres, app.game)}
                              font={Fonts.body} />
                    : null}

                    <Spacer minLength={16} />

                    <HStack>
                        <Spacer />
                        <VStack spacing={4}>
                            <RatingStarsV rating={app.rating}
                                          frame={{height: 20}}
                                          grayscale={1}
                                          brightness={-1}
                                          opacity={0.5} />
                            <Text t={Toolbox.formatRatingCount(app.ratingCount)}
                                  font={{size: 14}} />
                        </VStack>
                        <Spacer />
                        <VStack spacing={0} frame={{maxWidth: 100}} font={{size: 13}}>
                            {topButtonText ?
                                <Text lk={topButtonText} />
                            : null}
                            <Button t={Toolbox.appPriceLabel(app)} action={() =>
                                Toolbox.showAppStore(app.ITunesId)
                            } font={{size: 18, weight: FontWeight.bold}}
                              padding={{horizontal: 16, vertical: 6}}
                              foregroundStyle={Color.white}
                              background={"rgb(0, 0, 0, 0.6)"}
                              cornerRadius={Infinity}
                              opacity={app.onStore ? 1 : 0.5} />
                            {app.iapCount > 0 ?
                                <Text t={`${app.iapCount} IAPs`} />
                            : null}
                        </VStack>
                    </HStack>

                </VStack>
            </HStack>
        )
    }

    function actionButton(icon: string, count: number) {
        return (
            <HStack spacing={0} frame={{height: 40}} background={Color.tableBackground} cornerRadius={8}>
                <Spacer />
                <Image src={icon} frame={{height: 21}}/>
                <Spacer frame={{width: 12}} />
                <Text t={Toolbox.formatNumber(count)}
                      font={{size: 21, weight: FontWeight.semibold, design: FontDesign.rounded}} />
                <Spacer />
            </HStack>
        )
    }

    function appDetailButtonsV() {
        return (
            <VStack stretch>
                <HStack>
                    {actionButton(thumbsup, app.upvoteCount)}
                    {actionButton(thumbsdown, app.downvoteCount)}
                    {actionButton(checkmark, app.ownCount)}
                </HStack>
                {actionButton(bell, app.watchCount)}
            </VStack>
        )
    }

    function descriptionV() {
        return (
            <VStack alignment={HorizontalAlignment.leading} spacing={4}>
                <Text t={"Description"}
                      font={Fonts.title} />
                <Text t={description ?? app.description ?? "This app has no description."}
                      font={Fonts.body}
                      multilineTextAlignment={TextAlignment.leading}
                      lineLimit={7}
                      overlay={<LinearGradient colors={[Color.clear, Color.white]} />}
                />
            </VStack>
        )
    }

    return (
        <VStack spacing={16} padding={{bottom: 90}}>
            {appDetailHeaderV()}
            <VStack stretch alignment={HorizontalAlignment.leading} spacing={16} padding={{horizontal: 16}}>
                {appDetailButtonsV()}
                {app.assets ?
                    <AppDetailScreenshotsV screenshots={getScreenshots()} />
                : null}
                {descriptionV()}
                <SeeMoreInAppV />
            </VStack>
        </VStack>
    )
}