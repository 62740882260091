import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {AppStoreButtonSVG} from "./AppStoreButtonSVG";
import {ConfigConstants} from "../Constants/ConfigConstants";
import {NavigationLink} from "./SwiftUI/NavigationLink";

interface Props extends ViewProps {}

export const AppStoreButton: React.FC<Props> = (props) => {
    return (
        <NavigationLink destination={ConfigConstants.appStoreLink} {...props}>
            <AppStoreButtonSVG />
        </NavigationLink>
    )
}
