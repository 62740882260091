import React from 'react';
import './App.css';
import {HomeV} from "./Screens/Home/HomeV";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {NavigationView} from "./Components/NavigationView";
import {AppActivityListV} from "./Screens/Apps/App Activity/AppActivityListV";
import {AppDetailV} from "./Screens/Apps/App Detail/AppDetailV";
import {AppsV} from "./Screens/Apps/App List/AppsV";
import {DailyDealsV} from "./Screens/Daily Deals/DailyDealsV";
import {CollectionV} from "./Screens/Collections/Collection Detail/CollectionV";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<NavigationView />}>
                    <Route index element={<HomeV />} />
                    <Route path="activity/:type" element={<AppActivityListV />} />
                    <Route path="apps/:type" element={<AppsV />} />
                    <Route path="app/:id" element={<AppDetailV />} />
                    <Route path="collection/:id" element={<CollectionV />} />
                    <Route path="dailydeals" element={<DailyDealsV />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
