import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {Header} from "../Screens/Home/Header";
import {Outlet} from "react-router-dom";
import {VStack} from "./SwiftUI/VStack";
import {Color} from "./SwiftUI/Enums/Color";

interface Props extends ViewProps {}

export const NavigationView: React.FC<Props> = (props) => {
    return (
        <VStack background={Color.groupedBackground}>
            <VStack style={{width: "100%", alignItems: "stretch"}} frame={{maxWidth: 880}} background={Color.white}>
                <Header />
                <Outlet />
            </VStack>
        </VStack>
    );
};
